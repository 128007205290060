import React from "react"
import {graphql} from "gatsby"
import SingleLanding from "../components/SingleLanding";

export default function LandingTemplates({data}) {
    const {
        landings: {nodes: landingNode},
    } = data

    const landing = landingNode[0]
    console.log(landing);
    return (
        <SingleLanding title={landing.title} grigliaOggetti={landing.griglia_oggetti} metaTitle={landing.metaTitle} metaDescription={landing.metaDescription?.metaDescription} footerDescription={landing.footerDescriptionLanding?.footerDescriptionLanding} />
    )
}

export const query = graphql`
  query GetSingleLanding($slug: String) {
    landings: allContentfulLanding(filter: { slug_landing: { eq: $slug } }) {
      nodes {
        title: titolo_landing
        footerDescriptionLanding {
          footerDescriptionLanding
        }
        metaTitle
        metaDescription {
          metaDescription
        }
        griglia_oggetti {
          titolo
          designer
          indirizzo
          fotoAnteprima {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        } 
      }
    }
  }
`
